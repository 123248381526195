





















































import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import Avatar from '@/modules/community/common/components/avatar.vue'
import { DraftViewModel } from '../../../viewmodels/draft-viewmodel'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'

@Observer
@Component({
  components: {
    'post-list-item-content': () => import('@/modules/community/common/components/post-list-item-content.vue'),
    Avatar,
  },
})
export default class PostListItem extends Vue {
  @Inject() vm!: DraftViewModel
  @Prop({}) postStore!: PostStore
  walletStore = walletStore
  createPostController = createPostController
  postController = postController
}
